import React, { useContext, useCallback, useEffect, useState, useRef } from 'react';
import { NudgeSpotlight } from '@atlassiansox/nudge-tooltip';

import { layers } from '@atlaskit/theme/constants';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { markErrorAsHandled } from '@confluence/graphql';
import {
	ONBOARDING_NUDGE_EXPERIENCE,
	ExperienceTrackerContext,
	ExperienceFailure,
} from '@confluence/experience-tracker';
import {
	GenericErrorBoundary,
	Attribution,
	ErrorDisplay,
	isUnauthorizedError,
} from '@confluence/error-boundary';
import { isElementInViewport } from '@confluence/dom-helpers';

import { fireEvent } from './AnalyticEvent';

type NudgeProps = React.ComponentProps<typeof NudgeSpotlight>;
type NudgeSpotlightRef = {
	toggleCardVisibility?: (visible: boolean) => void;
	forceUpdateCardPosition?: () => void;
};
export type OnboardingNudgeProps = {
	source: string;
	actionSubjectId: string;
	wrapper?: any;
	renderChildrenWhileLoading?: boolean;
	onboardingErrors?: Error[];
} & NudgeProps;

export const ELEMENTS_NOT_IN_NAV = {};

// Timer helper function used to help with rerendering OnboardingNudgeTooltip
export const autoAdjustPosition = (
	fn: Function | undefined,
	{ interval = 500, cycles = 10 }: { interval: number; cycles: number },
) => {
	let i = 0;
	const timer = setInterval(() => {
		fn?.();
		i++;
		if (i === cycles) {
			clearInterval(timer);
		}
	}, interval);
	return timer;
};

function isErrorHandled(error: Parameters<typeof markErrorAsHandled>[0]) {
	// Mark unauthorized error as handled and not report to monitoring client
	if (isUnauthorizedError(error) || error?.graphQLErrors?.[0]?.message === 'unauthorized') {
		markErrorAsHandled(error);
		return true;
	}
	return false;
}

export const OnboardingNudgeComponent: React.FC<OnboardingNudgeProps> = (props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const {
		hidden,
		children,
		source,
		actionSubjectId,
		heading,
		content,
		width = 320,
		wrapper,
		zIndex = layers.blanket(),
		position,
		offset,
		onShow,
		onHide,
		onClick,
		onOutsideClick,
		onboardingErrors,
	} = props;
	const Wrapper = wrapper || React.Fragment;

	const target = `onboarding-nudge-${actionSubjectId}`;
	const experienceTracker = useContext(ExperienceTrackerContext);

	const nudgeSpotlightRef = useRef<NudgeSpotlightRef>(null);

	// used to turn off effects for outsideClick
	const [outsideClicked, setOutsideClicked] = useState(false);

	/**
	 * The point of this local hidden is to hide the nudge when the element is no longer in view
	 * This local hidden value is then changed when the element is back in view.
	 */
	const [isLocalHidden, setIsLocalHidden] = useState(false);

	useEffect(() => {
		if (!hidden) {
			experienceTracker.start({
				name: ONBOARDING_NUDGE_EXPERIENCE,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hidden, experienceTracker]);

	// Scroll fix for nudges that will be covered by global nav
	useEffect(() => {
		let isScrolling;

		const handleMouse = () => {
			isScrolling && window.clearTimeout(isScrolling);

			isScrolling = setTimeout(() => {
				const element = document.getElementById(target);
				if (!element) return;

				let elementInViewport = isElementInViewport(element);
				// Elements not in the nav bar sometimes get hidden by the nav bar
				elementInViewport = element.getBoundingClientRect().top > 40;
				setIsLocalHidden(!elementInViewport);
			}, 250);
		};

		if (!hidden) {
			window.addEventListener('scroll', handleMouse, true);
		}

		return () => {
			window.removeEventListener('scroll', handleMouse, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hidden, outsideClicked]);

	// Timer to account for lazily loaded components and to update tooltip position with a force rerender
	useEffect(() => {
		let timer;
		if (!hidden && nudgeSpotlightRef && nudgeSpotlightRef.current) {
			timer = autoAdjustPosition(nudgeSpotlightRef?.current?.forceUpdateCardPosition, {
				interval: 500,
				cycles: 10,
			});
		}

		return () => {
			clearInterval(timer);
		};
	}, [nudgeSpotlightRef, hidden]);

	// ------ Behavioral Callbacks ------
	const handleOnHide = () => {
		fireEvent(createAnalyticsEvent, {
			type: 'sendTrackEvent',
			source,
			actionSubjectId,
			actionSubject: 'nudgeTooltip',
			action: 'hidden',
		});
		onHide?.();
	};

	const handleOnClick = () => {
		fireEvent(createAnalyticsEvent, {
			type: 'sendTrackEvent',
			source,
			actionSubjectId,
			actionSubject: 'nudgeTooltip',
			action: 'clicked',
		});
		onClick?.();
		experienceTracker.succeed({ name: ONBOARDING_NUDGE_EXPERIENCE });
	};

	const handleOnShow = () => {
		fireEvent(createAnalyticsEvent, {
			type: 'sendTrackEvent',
			source,
			actionSubjectId,
			actionSubject: 'nudgeTooltip',
			action: 'shown',
		});
		onShow?.();
	};

	const handleOnOutsideClick = () => {
		onOutsideClick?.();
		setOutsideClicked(true);
	};

	// If there is a known error it should not display the nudge the priority of error is higher than hidden
	if (onboardingErrors && onboardingErrors.length) {
		const ErrorDisplays = onboardingErrors!
			.filter((error) => {
				return !isErrorHandled(error);
			})
			.map((error) => {
				return (
					<>
						<ErrorDisplay error={error} key={error.message} />
						<ExperienceFailure
							name={ONBOARDING_NUDGE_EXPERIENCE}
							error={error}
							key={error.message}
							attributes={{ source, actionSubjectId }}
						/>
					</>
				);
			});

		return (
			<>
				{children}
				{ErrorDisplays}
			</>
		);
	}

	return hidden || isLocalHidden ? (
		<>{children}</>
	) : (
		<Wrapper>
			<NudgeSpotlight
				{...props}
				ref={nudgeSpotlightRef}
				heading={heading}
				content={content}
				width={width}
				hidden={hidden || isLocalHidden}
				hideNudgeOnClick
				hideSpotlightCardOnOutsideClick
				onHide={handleOnHide}
				onShow={handleOnShow}
				onClick={handleOnClick}
				onOutsideClick={handleOnOutsideClick}
				zIndex={zIndex}
				position={position}
				offset={offset}
			>
				{children}
			</NudgeSpotlight>
		</Wrapper>
	);
};

// Keeping the OnboardingNudge Wrapper to avoid anti-pattern
// https://pug.jira-dev.com/wiki/spaces/CFE/blog/2021/05/07/20702691734/PSA+avoid+this+ErrorBoundary+anti-pattern
export const OnboardingNudge = (props: OnboardingNudgeProps) => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const failedOnboardingNudge = useCallback(
		(error: Error) => {
			experienceTracker.fail({
				name: ONBOARDING_NUDGE_EXPERIENCE,
				error,
			});
		},
		[experienceTracker],
	);
	return (
		<GenericErrorBoundary
			attribution={Attribution.CC_ONBOARDING}
			onError={failedOnboardingNudge}
			renderOnError={() => {
				return <>{props.children}</>;
			}}
		>
			<OnboardingNudgeComponent {...props} />
		</GenericErrorBoundary>
	);
};
